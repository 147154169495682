import { template as template_4588aa7f2a044be486d4eac90a65bf9c } from "@ember/template-compiler";
const FKControlMenuContainer = template_4588aa7f2a044be486d4eac90a65bf9c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
