import { template as template_b1ee276c12544d6689bd6ef74b3a243e } from "@ember/template-compiler";
const FKText = template_b1ee276c12544d6689bd6ef74b3a243e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
