import { template as template_8601e3ad71db486e818594af6eb3e0b8 } from "@ember/template-compiler";
const FKLabel = template_8601e3ad71db486e818594af6eb3e0b8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
