import { template as template_320692c6f7f74b47b9f2dd57ce9452bf } from "@ember/template-compiler";
const WelcomeHeader = template_320692c6f7f74b47b9f2dd57ce9452bf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
